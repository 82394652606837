*{
  font-family: 'Montserrat',sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.margin-auto{
  margin: 0 auto;
}

.circuloCorBorda{
  padding: 4px;
  border: 1px solid #666;
  border-radius: 50%;
  margin: 0 3px;
}

.circuloCor{
  width: 40px;
  height: 40px;
  border: 1px solid #666;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 4px;
}

.circuloCor2{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 4px;
}

.quadrado{
  width: 50px;
  height: 50px;
  border: 1px solid #333;
  cursor: pointer;
}

.quadrado-tamanho{
  width: 50px;
  height: 50px;
  border: 1px solid #333;
  cursor: pointer;
}

.quadrado-tamanho-sel{
  background-color: black;
  color: white;
}

.bg-preto{
  background-color: #000;
}

.bg-branco{
  border: 1px solid #000;
  background-color: #fff;
}

.bg-azul{
  background-color: #2032a2;
}

.bg-rosa{
  background-color: #ce2d6b;
}

.l-h-tamanho{
  line-height: 50px;
  margin: 0 3px;
  border-radius: 4px;
  border-color: #999;
}

.cemPor{
  width: 100%;
}

.fw-100{
  font-weight: 100;
}

.fw-200{
  font-weight: 200;
}

.fw-300{
  font-weight: 300;
}

.br-0{
  border-radius: 0;
}

.br-1{
  border-radius: 1px;
}

.br-2{
  border-radius: 2px;
}

input, select, option{
  font-weight: 300 !important;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 300;
}

.cor-selecionada {
  border: 5px solid #cccccc;
}

.h30-w30{
  height: 30px;
  width: 30px;
}

.alert-warning{

  padding: 10px 20px;
}

.alert-warning button{

  background: none;
  border: none;
  float: right;
  font-size: 2em;
}

.awssld__content{

  background: none !important;
}

.dropdown-menu {

  border-radius: 1px;
}

.navbar-toggler{

  border-radius: 1px !important;
}

.card-sombra{
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-sombra:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {

  .nav-item{

    text-align: left;
  }

  .m-0-mob{

    margin: 0 !important;
  }

  .pb-10-mob{

    padding-bottom: 10px !important;
  }

  .mt-10-mob{

    margin-top: 10px !important;
  }

  .ml-0-mob{

    margin-left: 0 !important;
  }

  .mt-2-mob{

    margin-top: .5rem !important;
  }

  .cem-por-mob{

    width: 100% !important;
  }

  .trinta-per-mob{

    width: 30% !important;
  }

  .setenta-per-mob{

    width: 70% !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
